import { Observable } from "rxjs"
import { SUSPENSE } from "../SUSPENSE"
import { EMPTY_VALUE } from "../internal/empty-value"
import { state, StateObservable } from "@rxstate/core"
import { useStateObservable } from "../useStateObservable"

/**
 * Accepts: A factory function that returns an Observable.
 *
 * Returns [1, 2]
 * 1. A React Hook function with the same parameters as the factory function.
 *  This hook will yield the latest update from the observable returned from
 *  the factory function.
 * 2. A `sharedLatest` version of the observable generated by the factory
 *  function that can be used for composing other streams that depend on it.
 *  The shared subscription is closed as soon as there are no subscribers to
 *  that observable.
 *
 * @param getObservable Factory of observables. The arguments of this function
 *  will be the ones used in the hook.
 *
 * @remarks If the Observable doesn't synchronously emit a value upon the first
 * subscription, then the hook will leverage React Suspense while it's waiting
 * for the first value.
 */
export default function connectFactoryObservable<A extends [], O>(
  getObservable: (...args: A) => Observable<O>,
  defaultValue: O | ((...args: A) => O),
): [
  (...args: A) => Exclude<O, typeof SUSPENSE>,
  (...args: A) => StateObservable<O>,
] {
  const args:
    | [(...args: A) => Observable<O>]
    | [(...args: A) => Observable<O>, O | ((...args: A) => O)] =
    defaultValue === EMPTY_VALUE
      ? [getObservable]
      : [getObservable, defaultValue]

  const obs = state(...(args as [(...args: A) => Observable<O>]))
  return [(...input: A) => useStateObservable(obs(...input)), obs]
}
